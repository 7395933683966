.service {
    padding: 40px 80px;
    background-color: rgb(255, 244, 244);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}


.serviceSections {
    display: flex;
}

.serviceCard {
    width: 700px;
    background: rgb(240, 238, 238);
    border-radius: 4px;
    padding: 80px 60px;
}

.serviceCardSubmitButton {
    padding: 10px 12px;
    border-radius: 10px;
    background: #b45309;
    color: white;
    border: 1px solid white;
}

.service p {
    margin-left: 40px;
    flex: 1;
    align-self: center;
    line-height: 1.5 rem;
}

.privacy-policy {
    padding: 40px 80px;
    text-align: left;
}

.service-card-item {
    background: rgb(240, 238, 238);
    padding: 80px 60px;
    margin-left: 20px;
    border-radius: 20px;
    width: 400px;
    height: fit-content;
    margin-top: 10px;
}

.service-card-item p {
    margin: 0;
    margin-top: 30px;
    margin-bottom: 10px;
}

.service-card-item img {
    height: 150px;
    width: 100%;
}

.service-card-item:first-child {
    margin-left: 0;
    margin-top: 0;
}

.service-card-item button {
    margin-top: 10px;
}